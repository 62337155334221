<template>
  <zmAppPage :show-footer="false" :showHeaderLeft="true" title="跟读练习">
    <div class="extend zm-flex is-column pd15">
      <div class="top flex-1">
        <div class="tips">
          <div class="fs20 fb mb10">使用说明</div>
          <ul class="fc-6">
            <li>1、按住开始录音按钮，录制你听到的英文单词</li>
            <li>2、点击播放按钮，播放你的单词录音</li>
            <li>3、点击重听单词按钮，重新播放英文单词</li>
          </ul>
        </div>
        <div class="word-box mt20">
          <div class="word" v-if="!playFinish">
            <div class="en mb10 fs26">{{ currentWord.text }}</div>
            <div class="cn fs18">{{ currentWord.textCn }}</div>
          </div>
          <div class="word" v-else>单词一轮播放完成</div>
          <div class="tc mt10">
            <van-button icon="share-o" type="info" @click="onChange(false)" v-if="!playFinish">换一个</van-button>
              <van-button icon="share-o" type="info" @click="init()" v-else >重新开始</van-button>
          </div>
        </div>
      </div>
      <read-btn @repeat="playWord"></read-btn>
    </div>
  </zmAppPage>
</template>

<script>
import ReadBtn from '@/pages/unitWord/components/ReadBtn';
import { mapGetters } from 'vuex';
import {randomNumberOne} from '@/utils/src/tools';

export default {
  name: 'ReadRecord',
  components: { ReadBtn },
  data() {
    return {
      randomIndex: 0,
      isPlay: false,
      playFinish: false,
      sec: 0,
      secTemp: 0,
      readingList: []
    };
  },
  computed: {
    ...mapGetters(['wordList']),
    currentWord() {
      return this.readingList[this.randomIndex] || {text: '',textCn: ''};
    }
  },
  watch: {},
  created() {
      this.init();
  },
  methods: {
    init() {
      this.initWordList();
      this.onChange('1');
    },
    cutSec(num) {
      this.sec = num + '"';
      num -= 1;
      let timer = null;
      if (num > -1) {
        let timer = setTimeout(() => {
          this.cutSec(num);
        }, 1000);
      } else {
        this.sec = this.secTemp;
        this.isPlay = false;
        clearTimeout(timer);
        timer = null;
      }
    },
    play(audioSrc) {
      let audio = new Audio(),
        numArr = this.sec.split('"'),
        vm = this;
      audio.autoplay = true;
      this.isPlay = true;
      this.$nextTick(function() {
        vm.cutSec(+numArr[0]);
        audio.src = audioSrc;
      });
    },
    async playWord(item = this.currentWord) {
        this.sec = Math.ceil(item.trackLength / 1000) + '"';
        this.secTemp = Math.ceil(item.trackLength / 1000) + '"';
        await this.play(item.audio);
    },
    initWordList() {
      this.readingList = [...this.wordList];
      this.playFinish = false;
    },
    onChange(isFirst) {
        if (!isFirst) this.readingList.splice(this.randomIndex,1);
        if (this.readingList.length < 1) {
          this.playFinish = true;
        }
        this.randomIndex = randomNumberOne(0, this.readingList.length - 1);
        this.playWord(this.currentWord);
    }
  }
};
</script>
<style lang="scss" scoped>
.word-box {
  .word {
    background: lighten($--color-primary, 15%);
    border-radius: 0.1rem;
    padding: 0.3rem;
    color: #fff;
    text-align: center;
  }
}
</style>
